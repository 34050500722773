import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

const Code = () => {
  useEffect(() => {
		//import React, { useEffect } from 'react';
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	
		// You can also return a cleanup function if needed
		// return () => {
		//   // Cleanup code here
		// };
	  }, []); // Empty dependency array ensures that this effect runs only once when the component mounts
  return (
   <div className='container'>
     <br />
  
   <div className='row'>
    
      <div className='col-md-9 col-sm-12'>
        <div className='col-md-12 col-sm-12'>
      
    <div class=" rounded-3  p-3 pb-2">
		
    <div class="d-sm-flex align-items-center">
      <div class=" mb-2 mb-sm-0">
      <div class="icon-lg bg-orange bg-opacity-10 text-orange rounded-circle mb-2"><i class="fa fa-code fs-5"></i></div>
      </div>
      <h4 class="mb-2 mb-sm-0 ms-sm-3">Code Review</h4>
   
   
      
    </div>
  

  
    <div className='container'>
          <div className='row'>
           
            <div className='col-md-12  col-sm-12 py-3'>
            <br />
            <h6>Introduction</h6>
         
            <p className='lead mb-0'>In software development, security must be a priority from the start. Our Secure Code Review service examines your source code for vulnerabilities that could be exploited once deployed.</p>
            <br />
            <h6>What We Do</h6>
            <p>Using a blend of automated tools and manual expertise, our team conducts a thorough review of your source code. We identify security flaws, logic errors, and other vulnerabilities that pose a risk to your software's integrity and security.</p>
            <h6>Why It Matters</h6>
            <p>Vulnerabilities in source code can lead to serious security breaches</p>
           <div className='alert alert-info'>

           <h6>
            Get Started
            </h6>
            <p>Enhance the security of your code with our comprehensive Penetration Testing service. Reach out for an in-depth assessment that can help safeguard your data transactions.</p>
            <Link to="/contact" class=" btn btn-sm btn-primary-soft mb-0 ms-auto flex-shrink-0 mt-2">Request Now</Link>
           </div>
            

            </div>
            
            
          </div>

          
    </div>
    


  </div>
        </div>
        <div className='col-md-12'>
          <div className='row'>
          <div class="col-md-6 col-xl-4 fix-my-card">
  <div class="card card-body shadow text-center align-items-center h-100">

    <div class="icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-2"><i class="fa fa-phone fs-5"></i></div>
  
    <h5>Call us</h5>
    <p>Feel free to call us for service requests or questions. We're here to help! </p>
 
    <div class="d-grid gap-3 d-sm-block">
    <button class="btn btn-sm btn-primary-soft"><a href="tel:+966 501323208">+966 501323208</a></button>
     
    </div>
  </div>
</div>


<div class="col-md-6 col-xl-4 fix-my-card">
  <div class="card card-body shadow text-center align-items-center h-100">

    <div class="icon-lg bg-danger bg-opacity-10 text-danger rounded-circle mb-2"><i class="fa fa-inbox fs-5"></i></div>
  
    <h5>Email us</h5>
    <p>send email for questions or request help</p>
  
    <a href="mailto:info@breakthrosec.com" class="btn btn-link text-decoration-underline p-0 mb-0"><i class="fa fa-email me-1"></i>info@breakthrosec.com</a>
  </div>
</div>

<div class="col-xl-4 position-relative fix-my-card">
 


  <div class="card card-body shadow text-center align-items-center h-100">
  
    <div class="icon-lg bg-orange bg-opacity-10 text-orange rounded-circle mb-2"><i class="fa fa-globe fs-5"></i></div>
   
    <h5>Social media</h5>
    <p>start follwoing and find last news.</p>
  
    <ul class="list-inline mb-0">

    <li class="list-inline-item"> <a class="btn btn-sm bg-twitter px-2 mb-0" href="https://twitter.com/breakthrosec" target='_blank'><i class="fab fa-fw fa-twitter"></i></a> </li>
      <li class="list-inline-item"> <a class="btn btn-sm bg-linkedin px-2 mb-0" href="https://www.linkedin.com/company/breakthrosec/" target='_blank'><i class="fab fa-fw fa-linkedin-in"></i></a> </li>
    </ul>
  </div>
</div>


          </div>
          <br/>
        </div>
      </div>


    <div className='col-md-3  col-lg-3 col-sm-12 card x-card'>
      <br />
       <h5>Our Servcies</h5>
       <hr></hr>
       <ul className='py-3 cateo'>
       <li><Link className="nav-link" to="/services/mobile-penetration-testing">Mobile Pentration Testing</Link></li>
       <li><Link className="nav-link" to="/services/web-penetration-testing">Web Pentration Testing</Link></li>
      
       
       <li><Link className="nav-link" to="/services/Network-Penetration-Testing">Network Penetration Testing</Link></li>
       <li><Link className="nav-link" to="/services/Active-Directory-Penetration-Testing">AD Penetration Testing</Link></li>
       <li><Link className="nav-link" to="/services/API-Penetration-Testing">API Penetration Testing</Link></li>
       <li><Link className="nav-link" to="/services/code-review">Code Review</Link></li>

       </ul>

       </div>
       <br />

       <br />

     
   </div>
   <br />
   <br />
   <br />
   <br />
   <br></br>
   <br></br>
   <br></br>
   <br></br>
   
   </div>
  
 
  );
};

export default Code;
