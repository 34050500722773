
import React, { useState,useEffect } from 'react';

import { BrowserRouter as Router, Route, Link, Routes,Button } from 'react-router-dom';
const Nav = ({theme}) => {
	
	useEffect(() => {

	
	  }, []);
	
	
	  
	const [displayAccountsMenu, setDisplayAccountsMenu] = useState(false);
	const [open, setOpen] = useState(false);
	const [displayUserProfile, setDisplayUserProfile] = useState(false);

	const [displayProfile, setDisplayProfile] = useState(false);

	const toggleProfileDisplay = () => {
	  setDisplayProfile(!displayProfile);
	};
  
	const toggleUserProfile = () => {
		setDisplayUserProfile(!displayUserProfile);
	  };
	const handleToggleMenu = () => {
		setOpen(!open);
	  };
	
	  const handleCloseMenu = () => {
		setOpen(false);
	  };

	  const toggleAccountsMenu = () => {
		setDisplayAccountsMenu(!displayAccountsMenu);
	  };
	 
  return (
   
     <header class="navbar-light header-sticky tst card ">

	<nav class="navbar navbar-expand-xl">
		<div class="container">
		
	
		<Link to='/'>
  <div className={`logo navbar-brand ${theme === 'dark' ? 'navbar-brand-dark' : ''}`}>
    {/* Your logo content goes here */}
  </div>
</Link>
		
		
			<button 
			  className="navbar-toggler ms-auto mx-3 p-0 p-sm-2"
			  type="button"
			  data-bs-toggle="collapse"
			  data-bs-target="#navbarCollapse"
			  aria-controls="navbarCollapse"
			  aria-expanded={open ? 'true' : 'false'}
			  aria-label="Toggle navigation"
			  onClick={handleToggleMenu}
			
			>

			
				<span class="navbar-toggler-animation">
					<span></span>
					<span></span>
					<span></span>
				</span>
			</button>


			<div className={`navbar-collapse collapse ${open ? 'show' : ''}`} id="navbarCollapse">
				<ul class="navbar-nav navbar-nav-scroll mx-auto">
				
        <li class="nav-item"> <Link class="nav-link" to="/" onClick={handleCloseMenu}><i class="fa fa-home fs-8"></i> Home</Link>	</li>


				
					<li class="nav-item dropdown">
						<Link class="nav-link dropdown-toggle" to="#" id="listingMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Services</Link>
						<ul class="dropdown-menu" aria-labelledby="listingMenu">
					
						

					
							

						
							
					
						

							<li> <Link class="dropdown-item" to="/services/mobile-penetration-testing" onClick={handleCloseMenu}>Mobile App Penetration Testing</Link></li>
							<li> <Link class="dropdown-item" to="/services/web-penetration-testing" onClick={handleCloseMenu}>Web Application Penetration Testing</Link></li>
							<li> <Link class="dropdown-item" to="/services/Network-Penetration-Testing" onClick={handleCloseMenu}>Network Penetration Testing</Link></li>
              <li> <Link class="dropdown-item" to="/services/Active-Directory-Penetration-Testing" onClick={handleCloseMenu}>Active Directory Penetration Testing</Link></li>
              <li> <Link class="dropdown-item" to="/services/API-Penetration-Testing" onClick={handleCloseMenu}>API Penetration Testing</Link></li>
              <li> <Link class="dropdown-item" to="/services/code-review" onClick={handleCloseMenu}>Secure Code Review</Link></li>
						</ul>
					</li>

					<li className="nav-item dropdown">
      <a 
        className="nav-link dropdown-toggle" 
        href="#" 
        id="accounntMenu" 
        onClick={toggleAccountsMenu} 
        aria-haspopup="true" 
        aria-expanded={displayAccountsMenu ? "true" : "false"}
      >
        Platforms
      </a>
      <ul className={`dropdown-menu ${displayAccountsMenu ? 'show' : ''}`} aria-labelledby="accounntMenu">
        {/* Dropdown submenu 1 */}
        <li 
          className={`dropdown-submenu dropend ${displayUserProfile ? 'show' : ''}`} 
          onClick={toggleUserProfile}
        >
          <Link className="dropdown-item dropdown-toggle" to="https://cv.breakthrosec.com" onClick={(e) => e.preventDefault()}>
		  Recruitment          </Link>
          <ul className={`dropdown-menu ${displayUserProfile ? 'show' : ''}`} data-bs-popper="none">
            <li>
              <Link className="dropdown-item" to="https://cv.breakthrosec.com/createResume">CV Builder</Link>
            </li>
            <li>
              <Link className="dropdown-item" to="https://cv.breakthrosec.com/recruiter">Talent Search</Link>
            </li>
           
          </ul>
        </li>
      </ul>
    </li>
			
					<li class="nav-item"> <Link class="nav-link" to="/about">About Us</Link>	</li>
					
          <li class="nav-item"> <Link class="nav-link" to="/contact">Contact Us</Link>	</li>
				</ul>
			</div>

			<ul class="nav flex-row align-items-center list-unstyled ms-xl-auto">
			
				<li class="nav-item dropdown me-2">
					<button class="btn btn-link mode p-0 mb-0" id="bd-theme" type="button" aria-expanded="false" data-bs-toggle="dropdown" data-bs-display="static">
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-circle-half theme-icon-active fa-fw" viewBox="0 0 16 16">
							<path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"></path>
							<use to="#"></use>
						</svg>
					</button>

					<ul class="dropdown-menu min-w-auto dropdown-menu-end" aria-labelledby="bd-theme">
						<li class="mb-1">
							<button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="light">
								<svg width="16" height="16" fill="currentColor" class="bi bi-brightness-high-fill fa-fw mode-switch me-1" viewBox="0 0 16 16">
									<path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"></path>
									<use to="#"></use>
								</svg>Light
							</button>
						</li>
						<li class="mb-1">
							<button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="dark">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-moon-stars-fill fa-fw mode-switch me-1" viewBox="0 0 16 16">
									<path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"></path>
									<path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"></path>
									<use to="#"></use>
								</svg>Dark
							</button>
						</li>
						<li>
							<button type="button" class="dropdown-item d-flex align-items-center active" data-bs-theme-value="auto">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-circle-half fa-fw mode-switch me-1" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"></path>
									<use to="#"></use>
								</svg>Auto
							</button>
						</li>
					</ul>
				</li>
			
			

			
			</ul>
		

		</div>
	</nav>

</header>



  );
};

export default Nav;
