import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Privacy = () => {
  useEffect(() => {
		//import React, { useEffect } from 'react';
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	
		// You can also return a cleanup function if needed
		// return () => {
		//   // Cleanup code here
		// };
	  }, []); // Empty dependency array ensures that this effect runs only once when the component mounts
  return (
    <>
    <section class="pt-4 pt-lg-5">
	<div class="container">

		<div class="row mb-4">
			<div class="col-12 text-center">
				<h1 class="fs-2 mb-0">Privacy Policy</h1>
			
				<img src="../metis/privacy.jpeg" class="h-lg-400px rounded-3 mt-4" alt=""/>
			</div>
		</div>


		<div class="row">
			<div class="col-md-10 mx-auto">
				<div class="vstack gap-3">

					
					<div class="card p-0 bg-transparent">
					
						<div class="card-header border-bottom bg-transparent px-0">
							<h4 class="card-title mb-0">Privacy Policy for Breakthrough Security</h4>
						</div>

					
						<div class="card-body px-0">
              <p>
              Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you request services on our website.
              </p>
							<p>
                <strong>Information We Collect</strong>
                <br/>
                <strong>Personal Information</strong>: We collect the personal information you provide when you submit a service request. This includes your name, job title, email address, company name and domain, mobile number, and any message you include
                </p>
							<ul>
                <h5>How We Use Your Information</h5>
								<li>To process and respond to your service requests.</li>
								<li>To improve our services and website.</li>
								<li>For marketing purposes, if you have consented to receive such communications</li>
						  
							</ul>
              
              <ul>
              <h5>Sharing Your Information</h5>
                <li>We do not sell or rent your personal information to third parties.</li>
                <li>We may share your information with service providers who assist us in fulfilling your service requests, under confidentiality agreements.</li>
              </ul>
							<h6>Data Security</h6>
              <p>We implement security measures to protect your personal information from unauthorized access and use. However, no internet-based site can be 100% secure, and we cannot guarantee absolute security.
</p>
     <h6>Your Rights</h6>
     <p>You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.</p>
     <h6>Changes to Our Privacy Policy</h6>
     <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website.</p>
     <h6>Contact Us</h6>
     <p>If you have any questions about our Privacy Policy or the information we hold about you, please <Link to='/contact'>Contact us</Link> via the details provided on our website.</p>
						</div>
					</div>
				
				
			
				
				
				
			</div>
		</div>
</div>

	</div>
</section>
   
  </>
  );
};

export default Privacy;
