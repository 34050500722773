import React, { useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Web from './components/Web';
import Mobile from './components/Mobile';
import Code from './components/Code';
import Team from './components/Team';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Error from './components/Error';
import AD from './components/AD'
import API from './components/API'
import Network from './components/Network'
import Privacy from './components/Privacy'


const App = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'auto');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
  
    const getPreferredTheme = () => {
      if (storedTheme) {
        return storedTheme;
      }
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    };
  
    const setAndStoreTheme = (theme) => {
      if (theme === 'auto') {
        const preferredTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.setAttribute('data-bs-theme', preferredTheme);
        localStorage.setItem('theme', preferredTheme);
        setTheme(preferredTheme);
      } else {
        document.documentElement.setAttribute('data-bs-theme', theme);
        localStorage.setItem('theme', theme);
        setTheme(theme);
      }
    };
  
    setAndStoreTheme(getPreferredTheme());
  
    const showActiveTheme = (theme) => {
      const activeThemeIcon = document.querySelector('.theme-icon-active use');
      const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`);
      const svgOfActiveBtn = btnToActive.querySelector('.mode-switch use').getAttribute('href');
  
      document.querySelectorAll('[data-bs-theme-value]').forEach((element) => {
        element.classList.remove('active');
      });
  
      btnToActive.classList.add('active');
      activeThemeIcon.setAttribute('href', svgOfActiveBtn);
    };
  
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      if (storedTheme !== 'light' && storedTheme !== 'dark') {
        setAndStoreTheme(getPreferredTheme());
      }
    });
  
    showActiveTheme(getPreferredTheme());
  
    document.querySelectorAll('[data-bs-theme-value]').forEach((toggle) => {
      toggle.addEventListener('click', () => {
        const theme = toggle.getAttribute('data-bs-theme-value');
        localStorage.setItem('theme', theme);
        setAndStoreTheme(theme);
        showActiveTheme(theme);
      });
    });
  }, []);


  return (
    <Router>
      <>
        <Nav theme={theme}/>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/web-penetration-testing" element={<Web />} />
          <Route path="/services/mobile-penetration-testing" element={<Mobile />} />
          <Route path="/services/code-review" element={<Code />} />
          <Route path="/services/Network-Penetration-Testing" element={<Network />} />
          <Route path="/services/Active-Directory-Penetration-Testing" element={<AD />} />
          <Route path="/services/API-Penetration-Testing" element={<API />} />
          <Route path="/team" element={<Team />} />
          <Route path="/" element={<Home />} />
          <Route path='/Privacy' element={<Privacy/>}/>
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </>
    </Router>
  );
};

export default App;
