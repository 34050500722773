import React, { useEffect } from 'react';

const Team = () => {
	useEffect(() => {
		//import React, { useEffect } from 'react';
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	
		// You can also return a cleanup function if needed
		// return () => {
		//   // Cleanup code here
		// };
	  }, []); // Empty dependency array ensures that this effect runs only once when the component mounts
  return (
   <div className='container'>
   <div className="container">
	    <div className='row'>
			<div className='col-md-1'></div>
			<div className='col-md-10'>
			<br />
	<br />

   
      <h4>Our Team</h4>
       <p className='text-muted'>

	   Welcome to BreakThroSec's elite team—an assembly of seasoned professionals driven by an unyielding commitment to fortify your digital fortress. We're not just here to secure your online presence; we're your partners in empowerment, dedicated to orchestrating airtight defense strategies, leveraging cutting-edge innovations, and delivering unwavering protection for your digital universe. Join us on this transformative journey toward robust, impenetrable cybersecurity.

	   </p>
<br />
			</div>
			<div className='col-md-1'></div>
		</div>
   </div>
      <div className='container'>
		<div className='row'>
			<div className='col-md-1'></div>
			<div className='col-md-10'>

			<section class="py-0">
	<div class="container">
	
		<div class="row">


			<div class="col-sm-12 col-md-4 col-xl-4">
				<div class="card card-img-scale card-element-hover overflow-hidden bg-transparent">
					<div class="card-img-scale-wrapper rounded-3">
				
						<img src="./metis/360_F_547858830_cnWFvIG7SYsC2GLRDoojuZToysoUna4Y.jpg" class="card-img" style={{'height':'200px','width':'250px'}} alt=""/>
					
						<div class="card-img-overlay d-flex align-items-start flex-column p-3">
						
						
						
							<div class="btn-group hover-element d-flex mt-auto">
							<a href="https://www.linkedin.com/in/alharbiabdulrahman/" class="btn btn-white" target="_blank"><i class="fa-brands fa-linkedin text-linkedin"></i></a>
								<a href="https://twitter.com/abdulr7mann" class="btn btn-white" target="_blank"><i class="fa-brands fa-twitter text-twitter"></i></a>
							</div>
						</div>
					</div>
				
					<div class="card-body px-2 pb-0">
						<h5 class="card-title mb-1"><a href="#">Abdulrahman Alharbi</a></h5>
						<span className='badge bg-info'>Penetration Tester</span>
						<p>
						Abdulrahman brings over a decade of experience as a penetration tester since 2008, equipped with a B.S. in Cybersecurity. He holds professional certifications such as OSCP, OSWA, PTP.
						</p>
					</div>
				</div>
			</div>
		

		
			<div class="col-sm-12 col-md-4 col-xl-4">
				<div class="card card-img-scale card-element-hover overflow-hidden bg-transparent">
					<div class="card-img-scale-wrapper rounded-3">
					
						<img src="./metis/360_F_547858830_cnWFvIG7SYsC2GLRDoojuZToysoUna4Y.jpg" class="card-img" style={{'height':'200px','width':'250px'}} alt=""/>
					
						<div class="card-img-overlay d-flex align-items-start flex-column p-3">
						
						
						
							<div class="btn-group hover-element d-flex mt-auto">
							<a href="https://sa.linkedin.com/in/abdulrahman-albalawi-43401abb" class="btn btn-white" target="_blank"><i class="fa-brands fa-linkedin text-linkedin"></i></a>
								<a href="https://twitter.com/Abdulra47524863" class="btn btn-white" target="_blank"><i class="fa-brands fa-twitter text-twitter"></i></a>
							</div>
						</div>
					</div>
					
					<div class="card-body px-2 pb-0">
						<h5 class="card-title mb-1"><a href="#">Abdulrahman Albalawi</a></h5>
						<span className='badge bg-info'>Code Reviewer & DevSecOps
</span>
<p>
Abdulrahman has been performing secure code reviews since 2017, specializing in DevSecOps practices. He holds professional certifications such as OSCP,ePPTV2,CPTC.

</p>
					</div>
				</div>
			</div>
	

		
			<div class="col-sm-12 col-md-4 col-xl-4">
				<div class="card card-img-scale card-element-hover overflow-hidden bg-transparent">
					<div class="card-img-scale-wrapper rounded-3">
				
						<img src="./metis/360_F_547858830_cnWFvIG7SYsC2GLRDoojuZToysoUna4Y.jpg" class="card-img" style={{'height':'200px','width':'250px'}} alt=""/>
					
						<div class="card-img-overlay d-flex align-items-start flex-column p-3">
						
						
						
							<div class="btn-group hover-element d-flex mt-auto">
							<a href="https://linkedin.com/in/eng-rayan-omair-550594137" class="btn btn-white" target="_blank"><i class="fa-brands fa-linkedin text-linkedin"></i></a>
								<a href="https://x.com/0xrayan7" class="btn btn-white" target="_blank"><i class="fa-brands fa-twitter text-twitter"></i></a>
							</div>
						</div>
					</div>
					
					<div class="card-body px-2 pb-0">
						<h5 class="card-title mb-1"><a href="#">Rayan Omair</a></h5>
						
						<span className='badge bg-info'>Code Reviewer & PT</span>

<p>
Rayan has been performing secure code reviews and penetration testing since 2017. He holds professional certifications such as OSCP,OSWE,eWAPTx,CDP. 
</p>
					</div>
				</div>
			</div>
			
		</div>
	
	</div>
</section>
			</div>
			<div className='col-md-1'></div>
		</div>
	  </div>
<br />
<br/>   
<br />
</div>
  );
};

export default Team;
