import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
const Footer = () => {
  return (
    <footer class="container-fluid footer-background">
	<div class="container">
		<div class="row">
			<div class="col-md-10 col-xl-6 mx-auto text-center">
			
				<img src='../metis/br1.png' className='logo-footer'/>
				<p class="mt-3 text-white">Secure your systems, protect your future. Engage with Breakthrough Security today and reinforce your organization's defenses against the cyber threats of tomorrow. Your security is not just our priority—it's our mission</p>
		
				<ul class="nav justify-content-between text-primary-hover mt-3 mt-md-0">
					<li class="nav-item"><Link class="nav-link p-2 text-white" to="/about">About</Link></li>
					<li class="nav-item"><Link class="nav-link p-2 text-white" to="/services/web-penetration-testing">Services</Link></li>
					<li class="nav-item"><Link class="nav-link p-2 text-white" to="/Privacy">Privacy</Link></li>
					
					<li class="nav-item"><Link class="nav-link p-2 text-white" to="/contact">Contact us</Link></li>
				</ul>
		
				<ul class="list-inline mt-3 mb-0">
					
					<li class="list-inline-item"> 
						<a class="btn btn-white btn-sm shadow px-2 text-twitter" href="https://twitter.com/breakthrosec" target='_blank'>
							<i class="fab fa-fw fa-twitter"></i>
						</a> 
					</li>
					<li class="list-inline-item"> 
						<a class="btn btn-white btn-sm shadow px-2 text-linkedin" href="https://www.linkedin.com/company/breakthrosec/" target='_blank'>
							<i class="fab fa-fw fa-linkedin-in"></i>
						</a> 
					</li>
				</ul>
		
				<div class="text-body-secondary text-primary-hover mt-3"> Copyrights ©2024 Breakthrough </div>
			</div>
		</div>
		
	</div>
	<br />
	<br />
</footer>
  );
};

export default Footer;
