import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
const About = () => {
  useEffect(() => {
		//import React, { useEffect } from 'react';
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	
		// You can also return a cleanup function if needed
		// return () => {
		//   // Cleanup code here
		// };
	  }, []); // Empty dependency array ensures that this effect runs only once when the component mounts
  return (
    <>
    <section class="pt-4 pt-lg-5">
	<div class="container">

		<div class="row mb-4">
			<div class="col-12 text-center">
				<h1 class="fs-2 mb-0">About Us</h1>
			
				<img src="../metis/about.jpeg" class="h-lg-400px rounded-3 mt-4" alt=""/>
			</div>
		</div>


		<div class="row">
			<div class="col-md-10 mx-auto">
				<div class="vstack gap-3">

					
					<div class="card p-0 bg-transparent">
					
						<div class="card-header border-bottom bg-transparent px-0">
							<h4 class="card-title mb-0">About BreakThroSec</h4>
						</div>

					
						<div class="card-body px-0">
              <p>
              <strong>Breakthrough Security</strong> is a pioneering offensive security firm rooted in Saudi Arabia, with a laser focus on offensive security services. Our team, exclusively comprised of highly skilled Saudi nationals, stands at the forefront of the industry, bringing over a decade of collective experience to the table. Our mission is clear: to reinforce your digital defenses and secure your online presence. We are committed to excellence and fueled by a passion for innovation, seamlessly blending unparalleled human expertise with cutting-edge AI technology to consistently surpass expectations.              </p>

              <p>
              <strong>BreakThroSec's</strong> elite team—an assembly of seasoned professionals, each contributing unique skills and expertise to fortify your digital fortress. More than just security experts, we are your partners in empowerment, dedicated to orchestrating airtight defense strategies, leveraging cutting-edge innovations, and delivering unwavering protection for your digital universe. With a combined experience of over a decade, our team members bring a wealth of expertise. They hold professional certifications such as OSCP, OSWA, ePPTV2, CPTC, OSWE, eWAPTx, and CDP, showcasing their dedication to staying at the forefront of industry standards. 
              </p>
	           <div className='alert alert-info'>
             <Link to='/contact'>Join us</Link> on this transformative journey toward robust, impenetrable cybersecurity. Together, we strive to safeguard your digital assets and navigate the challenges of an increasingly interconnected world.
             </div>
						</div>
					</div>
				
				
			
				
				
				
			</div>
		</div>
</div>

	</div>
</section>
   
  </>
  );
};

export default About;
