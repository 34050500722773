import React, { useState , useRef,useEffect} from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const dropdownRef = useRef(null);
  useEffect(() => {
		//import React, { useEffect } from 'react';
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	
		// You can also return a cleanup function if needed
		// return () => {
		//   // Cleanup code here
		// };
 
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowOptions(false);
        }
      };
  
      window.addEventListener('click', handleClickOutside);
  
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };

  
	  }, []); // Empty dependency array ensures that this effect runs only once when the component mounts
  const [formData, setFormData] = useState({
		subject: '',
		first_name: '',
		last_name: '',
		job_title: '',
		email: '',
		phone_number: '',
		message: '',
    agreementChecked: false

	  });
    const [showOptions, setShowOptions] = useState(false);

    const options = [
      'Web Application Penetration Testing',
      'Mobile Application Penetration Testing',
      'Secure Code Review',
      'Network Penetration Testing',
      'AD Penetration Testing',
      'API Penetration Testing'
    ];
    const toggleOptions = () => {
      setShowOptions(!showOptions);
    };
  
    const handleCheckboxChange = (e) => {
      const { value, checked } = e.target;
      if (checked) {
        setFormData({
          ...formData,
          subject: [...formData.subject, value]
        });
      } else {
        setFormData({
          ...formData,
          subject: formData.subject.filter(item => item !== value)
        });
      }
    };
    
    const handleSelectChange = (e) => {
      const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
      setFormData({
        ...formData,
        subject: selectedOptions
      });
    };
    const remainingOptions = options.filter(option => !formData.subject.includes(option));

    const [isloading,setLoading] = useState(false)
	  const [recaptchaValue, setRecaptchaValue] = useState(null);
	  const [successMessage, setSuccessMessage] = useState('');
	  const [errorMessage, setErrorMessage] = useState('');
	  const [emailError, setEmailError] = useState(null);  
	  const [serviceTypeError, setServiceTypeError] = useState('');
    const recaptchaRef = useRef(null);
     
	  const [formErrors, setFormErrors] = useState({
      subject: '',
      firstName: '',
      job_title:'',
      last_name:'',
      message:'',
      company_name:'',
      email:'',
      phone_number:'',
      agreementChecked: ''
    });

    //
    const validateForm = () => {
      const errors = {};
  
      // Validate selectedOption
      errors.subject = formData.subject.length === 0 ? 'Please select an option.' : '';
      //console.log(errors.subject);
      // Validate firstName
      errors.firstName = formData.first_name.trim() ? '' : 'Please enter your first name.';
      errors.company_name = formData.company_name ? '' : 'Please enter your company name.';

      errors.last_name = formData.last_name ? '' : 'Please Enter the Last Name.';
      errors.job_title = formData.job_title ? '' : 'Please Enter the job title.';
      errors.message = formData.message ? '' : 'Please write a message';
      const emailRegex = /^\S+@\S+\.\S+$/;
      errors.email = emailRegex.test(formData.email) ? '' : 'Please enter a valid email address.';

    // Validate mobile
      const mobileRegex = /^\d{10}$/;
       errors.phone_number = mobileRegex.test(formData.phone_number) ? '' : 'Please enter a valid 10-digit mobile number.';
       errors.agreementChecked = formData.agreementChecked ? '' : 'Please agree to the terms and conditions.';

      // Set formErrors state
      setFormErrors(errors);
  
      // Check if there are any errors
      return Object.values(errors).every((error) => !error);
    };
  




    //
	  const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prevData) => ({
		  ...prevData,
		  [name]: type === 'checkbox' ? checked : value,
		}));
	  };

    const resetRecaptcha = () => {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    };
	  const handleRecaptchaChange = (value) => {
		setRecaptchaValue(value);
	  };
	
	  const handleSubmit = async () => {
		   
		    if(validateForm()){
          if (!recaptchaValue) {
            setErrorMessage('Please complete the reCAPTCHA verification.');
            setTimeout(() => {
            setErrorMessage('');
            }, 3000);
            return;
            
          }
          setLoading(true)
          try {
            // Make the POST request
            const response = await axios.post('/api/contact-us/', {
              ...formData,
              recaptcha_token: recaptchaValue,
            });
          
            // Check if the response status indicates success
            if (response.status >= 200 && response.status < 300) {
              // Set success message
              setSuccessMessage('Your message has been sent successfully!');
              // Reset the form and recaptcha value
              setFormData({
                subject: [],
                first_name: '',
                last_name: '',
                job_title: '',
                email: '',
                phone_number: '',
                message: '',
                company_name: '',
              });
              setRecaptchaValue(null);
              resetRecaptcha();
              // Clear success message after 5 seconds
              setTimeout(() => {
                setSuccessMessage('');
              }, 5000);
            }
            setLoading(true);
          } catch (error) {
            // Handle errors
            console.error('Error:', error.message);
            const res = error.response?.data;
           
            if (res!=undefined && res.detail && res.detail[0] && res.detail[0].msg) {
              // Set error message if detail.msg exists
              setErrorMessage(res.detail[0].msg);
              // Clear error message after 5 seconds
              setTimeout(() => {
                setErrorMessage('');
              }, 5000);
            } else {
              // Set a generic error message if detail.msg doesn't exist
              setErrorMessage('An error occurred. Please try again later.');
              // Clear error message after 5 seconds
              setTimeout(() => {
                setErrorMessage('');
              }, 5000);
            }
          
          
            
            if (error.response.data && error.response.data.detail){
            //console.log('Backend Error:', error.response.data);
      
      
      
            const errorDetail = error.response.data.detail[0];
            console.log(errorDetail)
            if (errorDetail.type === 'type_error.enum' && errorDetail.loc[1] === 'subject'){
             // console.log('Backend Error:', error.response.data);
      
      
              setErrorMessage("Oops! It appears that the selected service doesn't exist. Please double-check your selection and try again. If you continue to experience issues, feel free to reach out to our support team for assistance. Thank you for your understanding.")
      
              setTimeout(() => {
                setErrorMessage('');
                }, 5000);
              
            }
            }
      
      
      
            if (error.response?.data?.detail && Array.isArray(error.response.data.detail)){
           // console.log('Backend Error:', error.response.data);
            const emailValidationError = error.response.data.detail.find((detail) => detail.loc && detail.loc[1] === 'email' );
      
            setEmailError(error.response.data.detail[0].msg);
      
            if (emailValidationError){
      
              //setEmailError({ msg: emailValidationError.msg });
              setErrorMessage('Oops! It seems like the provided email address is invalid. Please make sure to enter a valid email with exactly one @ symbol. Your communication is important to us, and we want to ensure accurate information. Kindly double-check and re-enter a valid email address. Thank you');
      
              setTimeout(() => {
                setErrorMessage('');
                }, 5000);
      
            }
            
      
      
            }else{
           // console.log('Backend Error:', error.response.data);
            setErrorMessage(error.response?.data?.detail || 'An error occurred while processing your request.');
            }
            
          }
      
          setLoading(false)
          resetRecaptcha();
        }else{

           /// error validation
        //   console.log("error")
        }
		
	  };

  return (
   <>
 
	<div class="container">
		<br />
		<div class="row mb-5">
		<div className='col-md-1'></div>
			<div class="col-md-10">
			<br />
				<h3 className='text-center'>Contact Us</h3>
				<p class="lead mb-0">Navigate the future of cybersecurity with confidence. Reach out to Breakthrough Security and secure a partnership that prioritizes your safety in an unpredictable digital world.
</p>
			</div>
			<div className='col-md-1'></div>
		</div>

	
		<div className='container'>
    <div class="row g-4">

   
<div class="col-md-6 col-xl-4">
  <div class="card card-body shadow text-center align-items-center h-100">

    <div class="icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-2"><i class="fa fa-phone fs-5"></i></div>
  
    <h5>Call us</h5>
    <p>call us for request service and questions.</p>
 
    <div class="d-grid gap-3 d-sm-block">
            <button class="btn btn-sm btn-primary-soft"><a href="tel:+966 501323208">+966 501323208</a></button>

     
    </div>
  </div>
</div>

<div class="col-md-6 col-xl-4">
  <div class="card card-body shadow text-center align-items-center h-100">

    <div class="icon-lg bg-danger bg-opacity-10 text-danger rounded-circle mb-2"><i class="fa fa-inbox fs-5"></i></div>
  
    <h5>Email us</h5>
    <p>send email for questions or request help</p>
  
    <a href="mailto:info@breakthrosec.com" class="btn btn-link text-decoration-underline p-0 mb-0"><i class="fa fa-email me-1"></i>info@breakthrosec.com</a>
  </div>
</div>



<div class="col-xl-4 position-relative">
 


  <div class="card card-body shadow text-center align-items-center h-100">
  
    <div class="icon-lg bg-orange bg-opacity-10 text-orange rounded-circle mb-2"><i class="fa fa-globe fs-5"></i></div>
   
    <h5>Social media</h5>
    <p>Start following us and finding the latest news.
</p>
  
    <ul class="list-inline mb-0">
   
      <li class="list-inline-item"> <a class="btn btn-sm bg-twitter px-2 mb-0" href="https://twitter.com/breakthrosec" target='_blank'><i class="fab fa-fw fa-twitter"></i></a> </li>
      <li class="list-inline-item"> <a class="btn btn-sm bg-linkedin px-2 mb-0" href="https://www.linkedin.com/company/breakthrosec/" target='_blank'><i class="fab fa-fw fa-linkedin-in"></i></a> </li>
    </ul>
  </div>
</div>

</div>
</div>
    
</div>
<br />
<section>
	<div class="container">
		<div class="row">
	
			

			<div class="col-lg-12 col-sm-12 col-md-12">


				<div class="card bg-light p-4">
			
				
					<div class="card-header bg-light p-0 pb-3">
						<h3 class="mb-0">Send us message</h3>
					</div>

			
					<div class="card-body p-0">
						<form class="row g-4">
            <div class="col-md-6">
								<label class="form-label">Company Name *</label>
								<input type="text"  className={`form-control ${formErrors.company_name && 'is-invalid'}`}  name="company_name" value={formData.company_name} onChange={handleChange} required/>
                <div className="invalid-feedback">{formErrors.company_name}</div>
							</div>

              <div className="col-md-6">
  <label className="form-label" htmlFor="services">Select Services *</label>
  <div ref={dropdownRef} className={`input-group ${showOptions ? 'open' : ''}`}>
    <input
      type="text"
      className={`form-control ${formErrors.subject && 'is-invalid'}`} 
      placeholder="Select services..."
      value={Array.isArray(formData.subject) ? formData.subject.join(', ') : ''}
      readOnly
    />
    <button
      className="btn btn-outline-secondary dropdown-toggle"
      type="button"
      onClick={toggleOptions}
    >
      <span className="visually-hidden">Toggle Dropdown</span>
    </button>
    {showOptions && (
      <div className="dropdown-menu show" aria-labelledby="dropdownMenuButton">
        {options.map(option => (
          <div key={option} className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={option}
              id={option}
              checked={formData.subject.includes(option)}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor={option}>
              {option}
            </label>
          
          </div>
        
        ))}
      </div>
    )}


  </div>

  {formData.subject.length === 0 && (
    <div className="text-danger">{formErrors.subject}</div>
  )}
 
</div>


							<div class="col-md-6">
								<label class="form-label">Your Job title *</label>
								<input type="text"  className={`form-control ${formErrors.job_title && 'is-invalid'}`}  name="job_title" value={formData.job_title} onChange={handleChange} required/>
                <div className="invalid-feedback">{formErrors.job_title}</div>
							</div>


							<div class="col-md-6">
								<label class="form-label">Your First Name *</label>
								<input type="text"   className={`form-control ${formErrors.firstName && 'is-invalid'}`}  name="first_name" value={formData.first_name} onChange={handleChange} required/>
							
                <div className="invalid-feedback">{formErrors.firstName}</div>

							</div>


							<div class="col-md-6">
								<label class="form-label">Your Last Name *</label>
								<input type="text"  className={`form-control ${formErrors.last_name && 'is-invalid'}`}  name="last_name" value={formData.last_name} onChange={handleChange} required/>
                <div className="invalid-feedback">{formErrors.last_name}</div>

							</div>


							<div class="col-md-6">
								<label class="form-label">Your Company Email *</label>
								<input type="email" className={`form-control ${formErrors.email && 'is-invalid'}`} name="email" value={formData.email} onChange={handleChange} required/>
                <div className="invalid-feedback">{formErrors.email}</div>
								
							</div>
							
							<div class="col-md-6">
								<label class="form-label">Your Mobile number *</label>
								<input type="phone"  className={`form-control ${formErrors.phone_number && 'is-invalid'}`}  name="phone_number" value={formData.phone_number} onChange={handleChange} required />
                <div className="invalid-feedback">{formErrors.phone_number}</div>
							</div>
			
							<div class="col-12">
								<label class="form-label">Message *</label>
								<textarea className={`form-control ${formErrors.message && 'is-invalid'}`}  rows="3" name="message" value={formData.message} onChange={handleChange} required></textarea>
                <div className="invalid-feedback">{formErrors.message}</div>
							</div>
						
							<div class="col-12 form-check ms-2">
              <input
          type="checkbox"
          className={`form-check-input ${formErrors.agreementChecked && 'is-invalid'}`}
          id="exampleCheck1"
          checked={formData.agreementChecked}
          onChange={(e) => setFormData({ ...formData, agreementChecked: e.target.checked })}
        />
        <label className="form-check-label" htmlFor="exampleCheck1">
          {formErrors.agreementChecked ? (
            <>
              By submitting this form you agree to our terms and conditions. *
              <div className="invalid-feedback">{formErrors.agreementChecked}</div>
            </>
          ) : (
            'By submitting this form you agree to our terms and conditions. *'
          )}
        </label>
               
							</div>

							<div class="col-12">

							    {successMessage && <div className="alert alert-success">{successMessage}</div>}
							    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

							</div>	

              <div class="col-12">

{/* Add reCAPTCHA component 6LeYgwopAAAAAGwm0o57SQ7wqh99TAJCsN0mKVCb */}
<ReCAPTCHA ref={recaptchaRef} sitekey="6LeYgwopAAAAAGwm0o57SQ7wqh99TAJCsN0mKVCb" onChange={handleRecaptchaChange} />

</div>	
						
							<div class="col-12">
							    <button class="btn btn-primary mb-0" type="button" disabled={isloading}  onClick={handleSubmit}>
                  {isloading ? (
            <>
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              Sending...
            </>
          ) : (
            'Send Message'
          )}
     
								</button>
							</div>	

						

						</form>
					</div>
				
				</div>
				<br />
			</div>
            
			<div class="col-lg-5 col-sm-12 col-md-5 text-left  position-relative">
			       
						</div>

		
		</div>{/* end row*/}
	</div> {/*  end conainer  */}
</section>
   </>
  );
};

export default Contact;
